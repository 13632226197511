<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    </div>
    <div class="mb-3">
      <CCol v-if="filterCriteria.status">
        <CBadge color="primary" class="mr-2">
          Status: {{ filterCriteria.status }}
          <CButton close @click="filterCriteria.status = ''"></CButton>
        </CBadge>
      </CCol>

      <CCol v-if="filterCriteria.brand">
        <CBadge color="primary" class="mr-2">
          Brand: {{ filterCriteria.brand }}
          <CButton close @click="filterCriteria.brand = ''"></CButton>
        </CBadge>
      </CCol>

      <CCol v-if="filterCriteria.model">
        <CBadge color="primary" class="mr-2">
          Model: {{ filterCriteria.model }}
          <CButton close @click="filterCriteria.model = ''"></CButton>
        </CBadge>
      </CCol>

      <CCol v-if="filterCriteria.minPrice || filterCriteria.maxPrice">
        <CBadge color="primary" class="mr-2">
          Price: {{ filterCriteria.minPrice || "0" }} -
          {{ filterCriteria.maxPrice || "∞" }}
          <CButton close @click="clearPriceFilter"></CButton>
        </CBadge>
      </CCol>
    </div>

    <div>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <strong>Additional Filter</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CInput
                    v-model="priceMinFilter"
                    label="Price Min"
                    placeholder="20000"
                  />
                </CCol>
                <CCol>
                  <CInput
                    v-model="priceMaxFilter"
                    label="Price Max"
                    placeholder="500000"
                  />
                </CCol>
              </CRow>

              <!-- Row for Button -->
              <CRow class="d-flex justify-content-end mt-3">
                <CCol sm="auto">
                  <CButton
                    size="sm"
                    color="secondary"
                    class="ml-1"
                    @click="onCleanerClick"
                  >
                    Clear
                  </CButton>
                  <CButton
                    size="sm"
                    color="info"
                    class="ml-1"
                    @click="refreshTable"
                  >
                    Search
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Vehicle </strong> List </CCardHeader>
            <CCardBody>
              <!-- Filter Section -->

              <CDataTable
                :items="computedItems"
                :fields="fields"
                :column-filter="{ external: true, lazy: true }"
                :column-filter-value.sync="columnFilterValue"
                :sorter="{ external: true, resetable: true }"
                :sorter-value.sync="sorterValue"
                :items-per-page="itemsPerPage"
                :active-page="1"
                hover
                :loading="loading"
              >
                <template #show_index="{ index }">
                  <td class="py-2">
                    {{ index + 1 }}
                  </td>
                </template>

                <!-- <template #stockStatus-filter="{ item }">
                  <CMultiSelect
                    :options="stockStatusOptions"
                    :selected="selectedStockStatuses"
                    :selection="true"
                    search
                    searchPlaceholder=""
                    selectionType="tags"
                    @update="setStockStatusFilter"
                  >
                  </CMultiSelect>
                </template> -->

                <template #stockStatus="{ item }">
                  <td>
                    <CBadge
                      v-if="item.stockStatus.includes('Open')"
                      color="success"
                      >Open</CBadge
                    >
                    <CBadge v-if="item.stockStatus.includes('LOU')" color="info"
                      >LOU</CBadge
                    >
                    <CBadge
                      v-if="item.stockStatus.includes('Booking')"
                      color="warning"
                      >Booking</CBadge
                    >
                    <CBadge
                      v-if="item.stockStatus.includes('Sold')"
                      color="danger"
                      >Sold</CBadge
                    >
                    <CBadge
                      v-if="item.stockStatus.includes('Cancelled')"
                      color="secondary"
                      >Cancelled</CBadge
                    >
                    <!-- <CBadge
                      v-if="item.stockStatus === 'Available'"
                      color="light"
                      >Available</CBadge
                    > -->
                  </td>
                </template>
                <template #arrivalStatus="{ item }">
                  <td>
                    <CBadge :color="getArrivalBadge(item.arrivalStatus)">
                      {{ item.arrivalStatus }}</CBadge
                    >
                  </td>
                </template>

                <template #show_image="{ item }">
                  <td class="py-2">
                    <CImg
                      thumbnail
                      :src="getImage(item)"
                      height="70"
                      width="70"
                    />
                  </td>
                </template>
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template>
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCard>
                      <CCardHeader>
                        <p class="text-muted">Stock No: {{ item.stockNo }}</p>
                      </CCardHeader>
                      <CCardBody>
                        <CRow form class="form-group">
                          <CCol tag="label" sm="3" class="col-form-label">
                            K8 Documents
                          </CCol>
                          <CCol sm="9">
                            <CDataTable
                              :items="item.clearance.k8Documents"
                              :fields="k8DocumentFieldList"
                              striped
                              caption="Striped Table"
                            >
                              <template #document_link="{ item }">
                                <td>
                                  <CLink
                                    target="_blank"
                                    :href="getDocumentUrl(item)"
                                    >{{ getDocumentName(item) }}</CLink
                                  >
                                </td>
                              </template>
                            </CDataTable>
                          </CCol>
                        </CRow>

                        <!-- <p class="text-muted">
                          Status: {{ getLatesStockStatusName(item) }}
                        </p>
                        <p class="text-muted">Brand: {{ item.brandName }}</p>
                        <p class="text-muted">Model: {{ item.modelName }}</p> -->
                        <!-- <p class="text-muted">Price: {{ item.modelName }}</p> -->
                        <CButton
                          size="sm"
                          color="primary"
                          class="ml-1"
                          @click="showDetails(item)"
                        >
                          Details
                        </CButton>
                        <!-- <CButton
                        size="sm"
                        color="danger"
                        class="ml-1"
                        @click="showDeleteConfirmation(item)"
                      >
                        Delete
                      </CButton> -->
                      </CCardBody>
                    </CCard>
                  </CCollapse>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter> </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import StockApi from "../../lib/stockApi";

// const items = [];
// const fields = [
//   {
//     key: "show_index",
//     label: "#",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },

//   { key: "stockNo" },
//   { key: "yearMonth", label: "Year" },
//   {
//     key: "arrivalStatus",
//     label: "Arrival",
//   },
//   {
//     key: "stockStatus",
//     _style: "max-width:150px;",
//     label: "Status",
//   },
//   // { key: "brandName" },
//   // { key: "modelName" },
//   { key: "brandModelName", label: "Model" },
//   { key: "price" },
//   { key: "vehicleDescription", label: "Description" },
//   {
//     key: "show_image",
//     label: "Image",
//   },
//   {
//     key: "show_details",
//     label: "",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },
// ];

export default {
  name: "StockList",
  data() {
    return {
      // Filter states
      priceMinFilter: "",
      priceMaxFilter: "",
      loadedItems: [],
      columnFilterValue: {},
      tableFilterValue: "",
      sorterValue: { column: null, asc: true },
      // Pagination
      activePage: 1,
      itemsPerPage: 20,
      pages: 1,

      k8DocumentList: [],
      k8DocumentFieldList: [
        {
          key: "document_link",
          label: "Document",
          sorter: false,
          filter: false,
        },
      ],
      stockStatusOptions: [
        { value: "Open", text: "Open" },
        { value: "LOU", text: "LOU" },
        { value: "Booking", text: "Booking" },
        { value: "Sold", text: "Sold" },
        { value: "Cancelled", text: "Cancelled" },
      ],
      selectedStockStatuses: ["Open", "LOU"],
      // filterStockStatuses: [],
      filterCriteria: {
        status: "", // Default: show all
        brand: "",
        model: "",
        minPrice: "",
        maxPrice: "",
      },

      loading: true,
      items: [],
      infoList: [],
      fields: [
        {
          key: "show_index",
          label: "#",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },

        { key: "stockNo" },
        { key: "yearMonth", label: "Year",sorter: false },
        {
          key: "arrivalStatus",
          label: "Arrival",sorter: false
        },
        {
          key: "stockStatus",
          _style: "max-width:150px;",
          label: "Status",
          filter : false,
          sorter: false
        },
        // { key: "brandName" },
        // { key: "modelName" },
        { key: "brandModelName", label: "Model" ,sorter: false},
        { key: "price", filter: false,sorter: false },
        { key: "vehicleDescription", label: "Description" ,sorter: false},
        {
          key: "show_image",
          label: "Image",
          filter: false,
          sorter: false
        },
        {
          key: "show_details",
          label: "",
          _style: "width:1%",
          sorter: false,
          filter: false,
        },
      ],
      details: [],
      collapseDuration: 0,
      api: new StockApi(),
      warningModal: false,
      itemToDelete: {},
    };
  },
  mounted() {
    var self = this;
    self.refreshTable();
  },
  watch: {
    reloadParams() {
      this.refreshTable();
    },
  },
  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage,
        this.itemsPerPage,
      ];
    },
    computedK8DocumentList() {
      return this.k8DocumentList.map((item) => {
        return {
          ...item,
          documentName: this.getCollectionDocumentName(item),
          documentUrl: this.getCollectionDocumentUrl(item),
        };
      });
    },
    isAllowDelete() {
      if (auth.getRole() == "super") return true;
      return false;
    },
    // Preprocess items to add `stockStatus` before filtering
    preprocessedItems() {
      return this.items.map((item) => ({
        ...item,
        brandModelName: this.getBrandModelName(item),
        price: this.getPricing(item),
        vehicleDescription: this.getVehicleDescription(item),
        yearMonth: this.getMonthYear(item),
        arrivalStatus: this.getArrivalStatus(item),
        stockStatus: this.getStockStatus(item), // Add the new Status field
      }));
    },
    // Filter items based on the selected multi-select values
    computedItems() {
      return this.loadedItems.map((item) => {
        return {
          ...item,
          latestStatus: this.getLatesStockStatusName(item),
          brandName: this.getBrandName(item),
          modelName: this.getModelName(item),
          price: this.getPricing(item),
          brandModelName: this.getBrandModelName(item),
          vehicleDescription: this.getVehicleDescription(item),

          yearMonth: this.getMonthYear(item),
          arrivalStatus: this.getArrivalStatus(item),
          stockStatus: this.getStockStatus(item), // Add the new Status field
        };
      });
    },
    computedExportItemForOperatorRole() {
      return this.computedItems.map((item) => {
        return {
          orderNo: item.orderNo,
          providerOrderNo: item.providerOrderNo,
          creatorFullName: item.createdBy.fullName,
          creatorPhone: item.createdBy.phone,
          agentFullName: item.agent.fullName,
          agentPhone: item.agent.phone,
          companyName: item.companyName,
          companyRegNo: item.companyRegNo,
          projectName: item.projectName,
          customerName: item.customerName,
          myKadOrPassport: item.myKadOrPassport,
          residentialType: item.residentialType,
          residentialName: item.residentialName,
          appointmentDate: item.appointmentDate,
          fullAddress: this.getFullAddress(item.fullAddress),
          postcode: item.postcode,
          city: item.city,
          state: item.state,
          phone: item.phone,
          email: item.email,
          submittedDate: item.submittedDate,
          serviceProviderName: item.serviceProviderName,
          packageName: item.packageName,
          internalStatus: item.internalStatus,
          providerStatus: item.providerStatus,
          isPaid: item.isPaid,
        };
      });
    },
    computedExportItem() {
      return this.computedItems.map((item) => {
        return {
          //...item,
          orderNo: item.orderNo,
          providerOrderNo: item.providerOrderNo,
          creatorFullName: item.createdBy.fullName,
          creatorPhone: item.createdBy.phone,
          agentFullName: item.agent.fullName,
          agentPhone: item.agent.phone,
          companyName: item.companyName,
          companyRegNo: item.companyRegNo,
          projectName: item.projectName,
          customerName: item.customerName,
          myKadOrPassport: item.myKadOrPassport,
          residentialType: item.residentialType,
          residentialName: item.residentialName,
          appointmentDate: item.appointmentDate,
          fullAddress: this.getFullAddress(item.fullAddress),
          // address1: item.address1,
          // address2: item.address2,
          // address3: item.address3,
          postcode: item.postcode,
          city: item.city,
          state: item.state,
          phone: item.phone,
          email: item.email,
          submittedDate: item.submittedDate,
          serviceProviderName: item.serviceProviderName,
          packageName: item.packageName,
          internalStatus: item.internalStatus,
          providerStatus: item.providerStatus,
          isPaid: item.isPaid,
          agentCommision: item.agentCommision,
        };
      });
    },
    csvContent() {
      var role = auth.getRole();
      if (role == "operator") {
        this.computedExportItemForOperatorRole.unshift({
          orderNo: "Order No",
          providerOrderNo: "Official Order No",
          creatorFullName: "Creator Name",
          creatorPhone: "Creator",
          agentFullName: "Agent Name",
          agentPhone: "Agent Phone",
          companyName: "Company Name",
          companyRegNo: "Company Reg",
          projectName: "Project Name",
          customerName: "Customer Name",
          myKadOrPassport: "MyKad",
          residentialType: "Residential Type",
          residentialName: "Residential Name",
          appointmentDate: "Appointment Date",
          fullAddress: "Address",
          // address1: "Address1",
          // address2: "Address2",
          // address3: "Address3",
          postcode: "Postcode",
          city: "City",
          state: "State",
          phone: "Phone",
          email: "Email",
          submittedDate: "Submitted Date",
          serviceProviderName: "Services",
          packageName: "Package",
          internalStatus: "Internal Status",
          providerStatus: "Provider Status",
          isPaid: "Is Paid",
        });

        return this.computedExportItemForOperatorRole
          .map((item) => Object.values(item).join(","))
          .join("\n");
      } else {
        this.computedExportItem.unshift({
          orderNo: "Order No",
          providerOrderNo: "Official Order No",
          creatorFullName: "Creator Name",
          creatorPhone: "Creator",
          agentFullName: "Agent Name",
          agentPhone: "Agent Phone",
          companyName: "Company Name",
          companyRegNo: "Company Reg",
          projectName: "Project Name",
          customerName: "Customer Name",
          myKadOrPassport: "MyKad",
          residentialType: "Residential Type",
          residentialName: "Residential Name",
          appointmentDate: "Appointment Date",
          fullAddress: "Address",
          // address1: "Address1",
          // address2: "Address2",
          // address3: "Address3",
          postcode: "Postcode",
          city: "City",
          state: "State",
          phone: "Phone",
          email: "Email",
          submittedDate: "Submitted Date",
          serviceProviderName: "Services",
          packageName: "Package",
          internalStatus: "Internal Status",
          providerStatus: "Provider Status",
          isPaid: "Is Paid",
          agentCommision: "Commision",
        });

        return this.computedExportItem
          .map((item) => Object.values(item).join(","))
          .join("\n");
      }
    },
    csvCode() {
      return (
        "data:text/csv;charset=utf-8," + encodeURIComponent(this.csvContent)
      );
    },
    computeStartDate() {
      return moment(this.startDate).format("YYYY-MM-DDTHH:mm");
    },
    computeEndDate() {
      return moment(this.endDate).format("YYYY-MM-DDTHH:mm");
    },
  },

  methods: {
    onCleanerClick() {
      this.priceMinFilter = "";
      this.priceMaxFilter = "";
      this.columnFilterValue = {}; // Reset column-specific filters
      this.tableFilterValue = ""; // Reset global table filter
      this.refreshTable(); // Reload data or reset filtered items
    },
    getDocumentName(item) {
      if (item.document == null) return "Unknown";
      if (item.document.fileName == null) return "Unknown";

      return item.document.fileName;
    },
    getDocumentUrl(item) {
      var self = this;
      return apiUrl + "documents/file/" + item.documentId;
    },
    setStockStatusFilter(items) {
      console.log("setStockStatusFilter");
      this.selectedStockStatuses = items;
      console.log(this.selectedStockStatuses);

      // this.filterStockStatuses = this.selectedStockStatuses;
      // console.log(this.filterStockStatuses);
    },
    getStockStatus(item) {
      let statusArray = [];
      if (item.isOpen) {
        statusArray.push("Open");
      }
      if (item.isLou) {
        statusArray.push("LOU");
      }
      if (item.isBooked) {
        statusArray.push("Booking");
      }
      if (item.isSold) {
        statusArray.push("Sold");
      }
      if (item.isCancelled) {
        statusArray.push("Cancelled");
      }

      // Join the status array into a single string separated by spaces
      var status = statusArray.length > 0 ? statusArray.join(" ") : "";
      return status;
    },
    getArrivalStatus(item) {
      if (item.arrivalStateDescription == "Received") return "R";
      else return "I";
    },
    getArrivalBadge(status) {
      return status === "R" ? "success" : "warning";
    },

    getMonthYear(item) {
      var month = "";
      var year = "";
      try {
        month = item.vehicle.month;
      } catch (error) {}
      try {
        year = item.vehicle.year;
      } catch (error) {}

      return year + "/" + month;
    },
    getVehicleDescription(item) {
      try {
        return item.vehicle.description;
      } catch (error) {
        return "";
      }
    },
    removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    },

    getImage(item) {
      // var url = apiUrl + "incident/image_display/" + item.imagepath;
      // console.log(item);
      // if (item.vehicle == null) return "";
      // if (item.vehicle.vehiclePhotoList == null) return "";
      // if (item.vehicle.vehiclePhotoList.length == 0) return "";
      var url = "";

      url = this.removeTrailingSlash(apiUrl) + item.vehicle.defaultImageUrl; // "Documents/File/" + item.vehicle.vehiclePhotoList[0].documentId;

      return url;
    },
    getPricing(item) {
      if (item.pricing == null) return "N/A";
      if (item.pricing.recommendedSalePrice == 0) return "N/A";
      return new Intl.NumberFormat("ms-MY", {
        style: "currency",
        currency: "MYR",
      }).format(item.pricing.recommendedSalePrice);
    },
    showDetails(item) {
      var self = this;
      self.$router.push({
        path: `/agents/vehicle/${item.id}`,
      });
    },
    getBrandName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.brand == null) return "N/A";
      return item.vehicle.brand.name;
    },
    getModelName(item) {
      if (item.vehicle == null) return "N/A";
      if (item.vehicle.model == null) return "N/A";
      return item.vehicle.model.name;
    },
    getBrandModelName(item) {
      try {
        return item.vehicle.brand.name + " " + item.vehicle.model.name;
      } catch (error) {
        return "N/A";
      }
    },
    getLatesStockStatusName(item) {
      if (item.latestStockStatus == null) return "N/A";
      if (item.latestStockStatus.stockStatus == null) return "N/A";
      return item.latestStockStatus.stockStatus.name;
    },
    getBadge(status) {
      return status === "Draft"
        ? "primary"
        : status === "Registered"
        ? "secondary"
        : status === "Shipped"
        ? "warning"
        : status === "ArriveAtPort"
        ? "success"
        : status === "ShowRoom"
        ? "info"
        : status === "Available"
        ? "danger"
        : status === "Booked"
        ? "light"
        : status === "Cancelled"
        ? "dark"
        : "";
    },
    download() {},
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    // refreshTable() {
    //   var self = this;
    //   self.loading = true;
    //   self.api
    //     .getForSaleList()
    //     .then((response) => {
    //       self.items = response.result;
    //       console.log(self.items);
    //       self.loading = false;
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //       self.loading = false;
    //     });
    // },
    refreshTable() {
      this.loading = true;
      //this.columnFilterValue = ["stockStatus", "open"];
      // this.columnFilterValue = { stockStatus: "open" };
      console.log("this.columnFilterValue", this.columnFilterValue);
      const params = {
        page: this.activePage,
        itemsPerPage: this.itemsPerPage,

        sorter: this.sorterValue,
        filters: this.columnFilterValue,
        search: this.tableFilterValue,

        priceMin: this.priceMinFilter,
        priceMax: this.priceMaxFilter,
        isOpen: true,
      };

      this.api
        .getList(params)
        .then((response) => {
          if (response.result) {
            this.loadedItems = response.result.items;
            this.pages = Math.ceil(
              response.result.totalItems / this.itemsPerPage
            );
            console.log("Loaded items:", this.loadedItems);
            console.log("Total pages:", this.pages);
          } else {
            this.loadedItems = [];
            this.pages = 1;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.toast("Error", "Failed to load data", "danger");
          this.loading = false;
        });
    },
    onEdit(item) {
      var self = this;
      self.$router.push({
        path: `/admins/Stock/${item.id}`,
      });
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.itemToDelete.id)
          .then((response) => {
            self.refreshTable();
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation(item) {
      var self = this;
      self.itemToDelete = item;
      self.warningModal = true;
    },
    addNew() {
      this.$router.push({ path: "/admins/Stock" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  },
};
</script>

<style scoped>
.sticky-filters {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 10px;
}
</style>